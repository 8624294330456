<template>
  <div class="bg-white rounded-10 p-10 text-left flex">
    <div class="w-1/2 pr-10 mr-10">
      <div class="text-c16 font-c6">Billetage</div>

      <div class="mt-10">
        <steepBilletage :montant="10000" @info="retour10"/>
        <steepBilletage :montant="5000" class="mt-4" @info="retour5"/>
        <steepBilletage :montant="2000" class="mt-4" @info="retour2"/>
        <steepBilletage :montant="1000" class="mt-4" @info="retour1"/>
        <steepBilletage :montant="500" class="mt-4" @info="retour05"/>
        <steepBilletage :montant="200" class="mt-4" @info="retour02"/>
        <steepBilletage :montant="100" class="mt-4" @info="retour01"/>
        <steepBilletage :montant="50" class="mt-4" @info="retour005"/>
        <steepBilletage :montant="25" class="mt-4" @info="retour002"/>
        <steepBilletage :montant="10" class="mt-4" @info="retour001"/>
        <steepBilletage :montant="5" class="mt-4" @info="retour0005"/>
      </div>
    </div>

    <div class="w-1/2 pl-20 text-c14 bloc2">
<!--      <div class="flex items-center justify-end">-->
<!--        <radio height="22px" width="22px" @info="retourIgnore"/>-->
<!--        <div class="text-c16 text-60 ml-3">Ignorer cette étape</div>-->
<!--      </div>-->

      <div class="mt-14 flex items-center">
        <div class="text-90">
          Solde disponible :
        </div>

        <div class="ml-2">
          {{ banque.amount.toLocaleString() }} F
        </div>
      </div>

      <div class="mt-6 flex items-center">
        <div class="text-90">
          Montant retiré :
        </div>

        <div class="text-red ml-2">
          {{ totalAmount.toLocaleString() }} F
        </div>
      </div>

      <div class="mt-6 flex items-center">
        <div class="text-90">
          Fond de caisse final :
        </div>

        <div class="ml-2">
          {{ (banque.amount - totalAmount).toLocaleString() }} F
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import steepBilletage from "../caisseDetail/steepBilletage"
// import radio from '../../helper/form/radio'

export default {
  name: "Index",
  components: {
    steepBilletage,
    // radio,
  },
  props: {
  },

  data () {
    return {
      allBillet: [],
      forget: false,
      banque: null,
      // totalAmount: 0
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    totalAmount: function () {
      if (this.allBillet.length > 0) {
        let total = 0
        for (let item in this.allBillet) {
          total = total + (this.allBillet[item].count * this.allBillet[item].amount)
        }
        this.$emit('info', this.allBillet)
        return total
      } else {
        return 0
      }
    }
  },

  created () {
    this.banque = this.$store.getters.trackTemporal
  },

  methods: {
    retour10 (answer) {
      this.allBillet[0] = answer
    },

    retour5 (answer) {
      this.allBillet[1] = answer
    },

    retour2 (answer) {
      this.allBillet[2] = answer
    },

    retour1 (answer) {
      this.allBillet[3] = answer
    },

    retour05 (answer) {
      this.allBillet[4] = answer
    },

    retour02 (answer) {
      this.allBillet[5] = answer
    },

    retour01 (answer) {
      this.allBillet[6] = answer
    },

    retour005 (answer) {
      this.allBillet[7] = answer
    },

    retour002 (answer) {
      this.allBillet[8] = answer
    },

    retour001 (answer) {
      this.allBillet[9] = answer
    },

    retour0005 (answer) {
      this.allBillet[10] = answer
    },

    retourIgnore (answer) {
      this.forget = answer
    },

    // sender () {
    //   if (this.allBillet.length > 0) {
    //     let total = 0
    //     for (let item in this.allBillet) {
    //       total = total + (this.allBillet[item].count * this.allBillet[item].amount)
    //     }
    //     this.totalAmount = total
    //   } else {
    //     this.totalAmount = 0
    //   }
    //   this.$emit('info', this.allBillet)
    // }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
</style>
