<template>
  <div class="bg-white rounded-10 p-10 text-left">

    <div class="text-c16">
      <span class="font-c6 mr-2">Retrait bancaire :</span>
     {{ banqueName }} vers <span class="text-oho-blue-clear">Caisse Comptabilité (Recette)</span>
    </div>

    <div class="flex">
      <div class="w-1/2 pr-10 mr-10">
        <div class="mt-10">
          <div class="">
            <div class="text-c16 text-90">
              Solde disponible: <span class="text-black">{{ banque.amount.toLocaleString() }} F</span>
            </div>
          </div>

          <div class="mt-6">
            <div class="text-c16 text-90">
              Solde retiré: <span class="text-black"> {{ total.toLocaleString() }} F </span>
            </div>
          </div>

          <div class="mt-6">
            <div class="text-c16 text-90">
              Solde restant: <span class="text-black"> {{ (banque.amount - total).toLocaleString() }} F </span>
            </div>
          </div>
        </div>
      </div>

      <div class="w-1/2 pl-20">
        <div class="mt-14">
          <upload value="Pièce justificative" @info="retourUpload"/>

          <textare radius="5px" height="150px" class="mt-6"  @info="retourNote"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upload from '../../helper/form/uploadPreuve'
import textare from '../../helper/form/textarea'

export default {
  name: "Index",
  components: {
    upload,
    textare
  },
  props: {
    total: {
      type: Number,
      default: 0
    },

    banqueName: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      allBillet: [],
      forget: false,
      banque: null,
      response: {}
    }
  },

  created () {
    this.banque = this.$store.getters.trackTemporal
  },

  methods: {
    sender () {
      this.$emit('info', this.response)
    },

    retourUpload (answer) {
      this.response.file = answer
      this.sender()
    },

    retourNote (answer) {
      this.response.note = answer
      this.sender()
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
</style>
