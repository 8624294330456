<template>
  <div class="bg-white rounded-10 p-10 pt-20 pb-32 text-left flex justify-center">
    <div class="w-1/2">
      <div class="text-c12">
        Compte bancaire
      </div>
      <multiselect
          v-if="optionCompte.length > 0"
          background="#fff"
          :value-t="bankDefault"
          border="1px solid #DCDCE4"
          class="mt-2"
          :option="optionCompte"
          @info="retourCompte"
      />

      <div class="text-c12 mt-6">
        Moyen d'opération
      </div>
      <multiselect
          background="#fff"
          value-t="Espèce"
          border="1px solid #DCDCE4"
          class="mt-2"
          :option="optionMoyen"
          @info="retourMoyen"
      />

      <div v-if="response.moyen === 'Chèque'">
        <div class="text-c12 mt-6">
         Destinataire
        </div>
        <multiselect
            background="#fff"
            border="1px solid #DCDCE4"
            class="mt-2"
            :option="optionDestinataire"
            @info="retourDestinataire"
        />
      </div>

      <div class="text-c12 mt-6">
        Date du retrait
      </div>

      <datepicker placeholder="Cliquez ici" class="w-full mt-2" @info="retourDate"/>
    </div>
  </div>
</template>

<script>
import multiselect from '../../helper/form/multiselect'
import datepicker from '../../helper/form/datePicker'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    multiselect,
    datepicker
  },
  props: {
  },

  data () {
    return {
      optionCompte: [],
      response: {},
      allData: [],
      bankDefault: 'Selectionner',
      wallet: null,
      optionMoyen: ['Espèce', 'Chèque'],
      optionDestinataire: null
    }
  },

  created () {
    this.wallet = this.$store.getters.trackTemporal
    this.allBanque()
    this.getUserWallet()
  },

  methods: {
    retourCompte (answer) {
      this.response.compte = answer
      for (let item in this.allData) {
        if (this.allData[item].bankName === answer) {
          this.response.bank = this.allData[item].id
          this.$store.dispatch('saveTemporalItem', this.allData[item])
        }
      }
      this.sender()
    },

    retourDestinataire (d) {
      this.response.destinataire = d
    },

    retourMoyen (a) {
      this.response.moyen = a
    },

    allBanque () {
      http.get(apiroutes.baseURL + apiroutes.allBank)
          .then(response => {
            console.log(response)
            this.allData = response.banks
            const data = response.banks
            let tab = []
            for (let item in data) {
              tab.push(data[item].bankName)
              if (data[item].bankName === this.wallet.bankName) {
                this.bankDefault = data[item].bankName
              }
            }
            this.optionCompte = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    getUserWallet () {
      http.post(apiroutes.baseURL + apiroutes.userWallet, {
        "types": [
          "DIRECTION", "ACCOUNTING"
        ]
      })
          .then(response => {
            this.charge = false
            console.log(response)
            let tab = []
            for (let item in response) {
              tab.push(response[item].person.fullname)
            }
            this.optionDestinataire = tab
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourDate (answer) {
      this.response.date = answer
      this.sender()
    },

    sender () {
      if (this.response.date !== undefined && this.response.date !== null && this.response.compte) {
        this.$emit('info', this.response)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
